import * as Sentry from "@sentry/browser";
import { wasmIntegration } from "@sentry/wasm";

const environment = __ENV__.charAt(0).toUpperCase() + __ENV__.slice(1)
const appSettings = await import(`../../config/appsettings.${environment}.json`);

Sentry.init({
  dsn: appSettings.SentryDsn,
  integrations: [wasmIntegration()],
});
